import { JaaSMeeting } from '@jitsi/react-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../button' // Ensure this path matches your actual Button component
import { useAppSelector } from '@/store/redux/hooks' // Redux hook for accessing the state
import { sendVideoCallEvent } from '@/api/services/order.service' // Function to handle custom events
import { useRole } from '@/components/custom/liveStreamSection/useRole'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { toggleFullscreen } from '@/utils'
import toast from 'react-hot-toast'
import { call_paramedic_button_clicked } from '@/lib/events'

function JaasMeet({ setHasJoinedCall, hasJoinedCall, roomName, fromSpecialPage }) {
  const { user } = useRole()
  const {
    user: { email, userId, role, fullName },
  } = useAppSelector((state) => state.userStore) // Customize according to your state structure

  const apiRef = useRef(null)
  const [isAudioMuted, setIsAudioMuted] = useState(true)
  const [isVideoMuted, setIsVideoMuted] = useState(true)
  const [isMediaMuted, setIsMediaMuted] = useState(false)
  // const [open, setOpen] = React.useState(false)

  const muteMedia = () => {
    document.querySelectorAll('audio, video').forEach((mediaElement: any) => {
      mediaElement.muted = true
    })
    setIsMediaMuted(true)
  }

  const unmuteMedia = () => {
    document.querySelectorAll('audio, video').forEach((mediaElement: any) => {
      mediaElement.muted = false
    })
    setIsMediaMuted(false)
  }

  const toggleAudio = () => {
    if (apiRef.current) {
      apiRef.current.executeCommand('toggleAudio')
      setIsAudioMuted((prev) => !prev)
    }
  }

  const toggleVideo = () => {
    if (apiRef.current) {
      apiRef.current.executeCommand('toggleVideo')
      setIsVideoMuted((prev) => !prev)
    }
  }

  const disconnectCall = () => {
    if (apiRef.current) {
      apiRef.current.executeCommand('hangup')
      setHasJoinedCall(false)
    }
  }

  useEffect(() => {
    return () => {
      if (apiRef.current) {
        apiRef.current.dispose()
      }
    }
  }, [])

  const clickOnDisconnectButton = () => {
    disconnectCall()
    // setOpen(true)
  }

  const meetRef = useRef(null)

  const handleFullScreen = () => {
    toggleFullscreen('jaasMeet')
  }

  const handleVideoCall = () => {
    call_paramedic_button_clicked({userId: userId, email: email, role: role, callType :'video'})
    sendVideoCallEvent(roomName, { userId, email, role, callType: 'video' })
  }

  const handleAudioCall = () => {
    sendVideoCallEvent(roomName, { userId, email, role, callType: 'audio' })
  }

  return (
    <>
      {!hasJoinedCall ? (
        <div className='flex h-full w-full items-center justify-center bg-gray-900'>
          <Button onClick={() => setHasJoinedCall(true)}>Join Call</Button>
        </div>
      ) : (
        <div className='relative h-full w-full'>
          <Button onClick={handleFullScreen} variant='ghost' className=' absolute right-5 top-5 z-10 h-10 w-10 rounded-full bg-gray-100 p-1'>
            <Icon fontSize={20} icon={'solar:full-screen-bold-duotone'} />
          </Button>

          <div className='relative h-full w-full'>
            <div id={'jaasMeet'} className='h-[88%] w-full'>
              <JaaSMeeting
                appId=''
                roomName={`${import.meta.env.VITE_JAAS_ROOM_NAME}/${roomName}`}
                onReadyToClose={() => setHasJoinedCall(false)}
                configOverwrite={{
                  disableThirdPartyRequests: true,
                  disableLocalVideoFlip: true,
                  backgroundAlpha: 0.75,
                  startWithAudioMuted: isAudioMuted,
                  startWithVideoMuted: isVideoMuted,
                  disableModeratorIndicator: false,
                  startScreenSharing: false,
                  enableEmailInStats: true,
                  prejoinPageEnabled: false,
                }}
                interfaceConfigOverwrite={{
                  TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop', 'fullscreen', 'hangup'],
                  MOBILE_APP_PROMO: true,
                  TILE_VIEW_ENABLED: true,
                  SHOW_BRAND_WATERMARK: true,
                  // TOOLBAR_BUTTONS: [<></>],
                  BRAND_WATERMARK_LINK: 'https://redhealth.gumlet.io/build/images/red-health-logo.svg?w=240&dpr=2.0',
                }}
                userInfo={{
                  displayName: fullName,
                  email: email,
                }}
                onApiReady={(externalApi) => {
                  apiRef.current = externalApi
                }}
                getIFrameRef={(iframeRef) => {
                  iframeRef.style.height = '100%'
                  iframeRef.style.minHeight = '200px'
                  iframeRef.style.width = '100%'
                  iframeRef.style.padding = '10px'
                  iframeRef.style.overflow = 'hidden'
                  iframeRef.style.backgroundColor = 'white'
                }}
              />
            </div>
            {/* <div className=" justify-around items-center flex space-x-4 text-gray-600 pt-3">
            <Button onClick={toggleAudio} variant='ghost' className=' rounded-full p-1 h-12 w-12 bg-gray-100'>{isAudioMuted ? <div><Icon fontSize={25} icon={'mynaui:microphone-slash'} /><span className='h-5 w-1 border-1' /></div> : <Icon fontSize={25} icon={'solar:microphone-2-line-duotone'} />}</Button>
            <Button onClick={toggleVideo} variant='ghost' className=' rounded-full p-1 h-12 w-12 bg-gray-100'>{isVideoMuted ? <div><Icon fontSize={25} icon={'hugeicons:video-off'} /><span className='h-5 w-1 border-1' /></div> : <Icon fontSize={25} icon={'solar:videocamera-record-line-duotone'} />}</Button>
            <Button onClick={isMediaMuted ? unmuteMedia : muteMedia} variant='ghost' className=' rounded-full p-1 h-12 w-12 bg-gray-100'>{isMediaMuted ? <div><Icon fontSize={25} icon={'solar:volume-cross-line-duotone'} /><span className='h-5 w-1 border-1' /></div> : <Icon fontSize={25} icon={'solar:volume-small-line-duotone'} />}</Button>
            <Button onClick={clickOnDisconnectButton} variant='default' className=' rounded-full p-1 h-12 w-12 bg-red-600'>
              <Icon fontSize={25} icon={'solar:end-call-rounded-line-duotone'} />
            </Button>
          </div> */}

            {!fromSpecialPage && <div className=' flex items-center justify-around  gap-x-4 px-4  text-slate-700'>
              {/* <Button onClick={handleAudioCall} variant='outline' className=' w-full  rounded-md py-2'>
                                <Icon fontSize={24} icon={'solar:phone-rounded-line-duotone'} className='mr-2' />
                                Voice Call
                            </Button> */}
              <Button onClick={handleVideoCall} variant='outline' className=' w-full rounded-md  py-2'>
                <Icon fontSize={20} icon={'solar:videocamera-record-line-duotone'} className='mr-2' />
                Call Paramedic
              </Button>
            </div>}

            {/* <Dialog open={open} onOpenChange={setOpen} >
            <DialogContent className="sm:max-w-[425px]">

              <DialogHeader>
                <DialogDescription>
                  Make changes to your profile here. Click save when you're done.
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog> */}
          </div>
        </div>
      )}
    </>
  )
}

export default JaasMeet
